import { createRouter, createWebHashHistory } from 'vue-router';

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/HomeView.vue'),
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import('../views/ProfileView.vue'),
  },
  {
    path: '/auth',
    name: 'auth',
    component: () => import('../views/AuthView.vue'),
  },
  {
    path: '/user',
    name: 'user',
    component: () => import('../views/UserView.vue'),
  },
  {
    path: '/medal',
    name: 'medal',
    component: () => import('../views/MedalView.vue'),
  },
  {
    path: '/medal-log',
    name: 'medal-log',
    component: () => import('../views/MedalLogView.vue'),
  },
  {
    path: '/marquee',
    name: 'marquee',
    component: () => import('../views/MarqueeView.vue'),
  },
  {
    path: '/training',
    name: 'training',
    component: () => import('../views/TrainingView.vue'),
  },
  {
    path: '/training-guest',
    name: 'training-guest',
    component: () => import('../views/TrainingGuestView.vue'),
  },
  {
    path: '/diving-log',
    name: 'diving-log',
    component: () => import('../views/DivingLogView.vue'),
  },
  {
    path: '/service',
    name: 'service',
    component: () => import('../views/ServiceView.vue'),
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
