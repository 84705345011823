<template>
  <div class="nav-bar">
    <div class="logo">
      <input type="image" class="nav-item" src="https://i.imgur.com/CxcL1Vf.jpge" alt="logo"
        @click="redirectPage('home')">
    </div>
    <ul class="nav">
      <li class="nav-item" :class="tabName === 'marquee' ? 'active' : ''" @click="redirectPage('marquee')"
        v-show="!$parent.isStudent && $parent.isSignIn">跑馬燈</li>
      <li class="nav-item" :class="tabName === 'medal-log' ? 'active' : ''" @click="redirectPage('medalLog')"
        v-show="$parent.isSignIn">徽章紀錄</li>
      <li class="nav-item" :class="tabName === 'medal' ? 'active' : ''" @click="redirectPage('medal')"
        v-show="$parent.isSignIn">徽章列表</li>
      <li class="nav-item" :class="tabName === 'training' ? 'active' : ''" @click="redirectPage('training')">團練表單</li>
      <li class="nav-item" :class="tabName === 'diving-log' ? 'active' : ''" @click="redirectPage('divingLog')"
        v-show="$parent.isSignIn">潛水日誌</li>
      <li class="nav-item" :class="tabName === 'user' ? 'active' : ''" @click="redirectPage('user')"
        v-show="!$parent.isStudent && $parent.isSignIn">小海綿列表</li>
      <li class="nav-item" :class="tabName === 'service' ? 'active' : ''" @click="redirectPage('service')">我們提供</li>
      <li class="nav-item" :class="tabName === 'auth' ? 'active' : ''" @click="redirectPage('auth')">{{ $parent.isSignIn
        ? '登出' : '登入' }}</li>
      <li class="nav-name-label" v-if="$parent.isSignIn && $parent.isStudent" @click="redirectPage('profile')">
        Hi! 小海綿 {{ $parent.user.nickname }}
        <span class="profile-tool-tip">
          積分: {{ $parent.user.point }}<br />
          等級: {{ level }}
        </span>
      </li>
      <li class="nav-name-label" v-else-if="$parent.isSignIn && !$parent.isStudent" @click="redirectPage('profile')">
        Hi! 教練 {{ $parent.user.nickname }} </li>
    </ul>
  </div>
</template>

<script>
import menuMixins from '@/mixins/menuMixins';

export default {
  mixins: [menuMixins],
};
</script>
