import { createApp } from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import MarqueeText from 'vue-marquee-text-component';
import AlertModal from '@/components/AlertModal.vue';
import NavTab from '@/components/NavTab.vue';
import OffCanvas from '@/components/OffCanvas.vue';
import Vue3Toasity, { toast } from 'vue3-toastify';
import router from './router';
import App from './App.vue';
import '@/assets/css/app.scss';
import '@/assets/css/font.css';
// import '@/assets/main.scss';

const app = createApp(App);
app.use(VueAxios, axios);
app.use(router);
app.use(Vue3Toasity, {
  autoClose: 5000,
  position: toast.POSITION.TOP_CENTER,
  hideProgressBar: true,
});
app.component('marquee-text', MarqueeText);
app.component('NavTab', NavTab);
app.component('OffCanvas', OffCanvas);
app.component('AlertModal', AlertModal);
app.mount('#app');
